<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import PayrollbyBranch from "./Payroll Report by Branch/PayrollbyBranch.vue";
import PayrollbyJobtitle from "./Payroll by Job Title/PayrollbyJobtitle.vue";
import PayrollbyDate from "./Payroll Report by Date/PayrollbyDate.vue";
import PayrollperEmployeeVue from "./Payroll Report per Employee/PayrollperEmployee.vue"
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    PayrollbyBranch,
    PayrollbyJobtitle,
    PayrollbyDate,
    PayrollperEmployeeVue
  },
  data() {
    return {
    };
  },
  methods: {
    clearTab(){
      console.log("clearTab");
      this.data = {};
    }
  },
  created() {
    
  }
};
</script>

<template>
  <Layout>
   
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.payroll.subItems.payrollreport')" />
  
  
    <b-tabs   :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
      <b-tab @click="clearTab()" active>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t("menu.menuitems.payroll.subItems.payroll-report-by-branch")}}</span>
        </template>


        <payrollby-branch></payrollby-branch>

      </b-tab>
      <b-tab @click="clearTab()">
        <template v-slot:title>
          <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t("menu.menuitems.payroll.subItems.payroll-report-by-job-title")}} </span>
        </template>
         

        <payrollby-jobtitle></payrollby-jobtitle> 

      </b-tab>

      <b-tab @click="clearTab()">
        <template v-slot:title>
          <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t("menu.menuitems.payroll.subItems.payroll-report-by-date")}} </span>
        </template>


        <payrollby-date></payrollby-date>

      </b-tab>

      <b-tab @click="clearTab()">
        <template v-slot:title>
          <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t("menu.menuitems.payroll.subItems.payroll-report-per-employee")}} </span>
        </template>
        <PayrollperEmployeeVue></PayrollperEmployeeVue>
      </b-tab>
</b-tabs>
  
  </Layout>
</template>
<style scoped>
</style>




